@font-face {
  font-family: RobotoLight;
  src: url('./fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBlond;
  src: url('./fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: MonseratBold;
  src: url('./fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MonseratBlack;
  src: url('./fonts/Montserrat-Black.ttf');
}

html, body {
  background-color: #F8F7F7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  display: none !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.carousel-control-next-icon {
  background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjklEQVR4nO3asQ3CQBAF0WliLdN/JyRImAgCygEhXUSO9HeZ18HXOPDpDiT9yhm4ARvNXYEX8Og+poD7GvMETjRWjglVlglVlglVlgllmVSWSWWZVJZJNarMto7LnzEHjY0YUl+f1k5DjkhhiRSWSGGJFJZIYYkUlkhR/oqHqAklWMfSEdfTlykPBqR/8Abo/nQfGMTYTAAAAABJRU5ErkJggg== ) !important;
}
.carousel-control-prev-icon {
  background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVR4nO3ZsQ3CQBQE0Wnin6D/SogQyCQ4oBwspAsQDbD/NK+CW40D+wySOhjAA7jQfMQOvIEbTRXwnCNewImGHJHCEikskcISKSyRwhIpLJGifBUPUSuU+NjmiH1+rra1rTKkfh6tM42VY0KVZUKVZUKVZUJZJpVlUlkmlWVSLVVmfP2evtPcmHcA138fRFrVAcl0dB8tu9fZAAAAAElFTkSuQmCC ) !important;
}
.carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}
.bg-header {
  background-color: #fff !important;
}
.tooltip {
  --bs-tooltip-bg: #6CCFF6;
  --bs-tooltip-max-width: 400px;
  min-width: 300px;
  opacity: 1 !important;
}
.tooltip-inner {
  border-radius: 20px !important;
}
.products-carousel {
  width: 80%;
}
.img-clients {
  width: 70%;
}
.clients-padding {
  padding: 20px 15px;
}
.player-ad-style {
  width: 100% !important;
  height: auto !important;
}


.col-md-6-custom {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  margin-bottom: 20px;
}
.header{
  background-color: transparent!important;
  font-size: 15px;
}
.App {
  /*text-align: center;*/
}
.bg-header-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #fff;
  transition-duration: 0.5s;
}
.bg-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #1A1A1A;
  /*background: rgb(26,26,26);
  background: linear-gradient(180deg, rgba(26,26,26,1) 0%, rgba(255,255,255,0) 100%);*/
  transition-duration: 0.5s;
  opacity: 0.9;
}
.service-container {
  padding-bottom: 200px;
}
.navbar-hidden {
  top: -100px !important;
  transition: all 0s, opacity 0.5s linear;
}
.navbar-show {
  top: 0 !important;
  transition: all 0s, opacity 0.5s linear;
}
.nav-link {
  color: #fff !important;
}
.font-heder{
  color: #000 !important;
  font-weight: 500;
  font-size: 17px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.footer{
  background-color: #000;
  color:#fff;
  text-align: left;
  padding: 35px;
}
.footer-text-title{
  font-size: 80px;
  font-weight: 100;
  line-height: 85px;
  text-align: justify;
  font-family: Impact, Haettenschweiler, 'MonseratBlack', sans-serif !important;
}
.footer-text-desc{
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.footer-gif{
  width: 100%;
}
.logo-wite-style {
  width: 150px;
}
.footer-text-subtitle{
  font-size: 35px;
  font-weight: 200;
  color: #fff;
  font-family: 'MonseratBold',sans-serif !important;
}
.form-footer{
  background-color: #000!important;
  color:#fff!important;
  border: 1px solid #fff;
  font-family: 'Roboto',sans-serif !important;
}
.form-footer::placeholder{
  color:#fff!important;
}
.button-footer{
  background-color: #ffffff!important;
  border-color: #000!important;
  font: larger;
  width:120px ;
  color: #000!important;
  font-family: 'RobotoBlond', sans-serif !important;
  font-weight: bold;
}
.line-footer{
  border-bottom: solid white 1px;
}
.social-media{
  width: 50px; 
}
.banner-us{
  color: #000;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}
.small-titles-us {
  font-size: 12px;
  text-align: left;
  font-weight: 300;
}
.titles-us{
  font-size: 40px;
  line-height: 50px;
  text-align: left;
  font-weight: 900;
  color: #6DCCCC;
}
.titles-benefits {
  font-size: 60px;
  text-align: left;
  font-weight: 900;
  color: #BF7CB5;
}
.benefits-container {
  width: 100%;
  background-color: #BF7CB5;
  border-radius: 50px;
  padding: 25px;
}
.benefits-card-title {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}
.benefits-card-desc {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
}
.benefit-row {
  max-width: 900px;
}
.service-title {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.service-container {
  width: 100%;
  background-color: #409ABF;
  border-radius: 50px;
  padding: 25px;
  cursor: pointer;
}
.tooltip-desc {
  text-align: left;
  background-color: #6CCFF6;
  padding: 20px 10px 20px 0;
  font-size: 20px;
  border-radius: 20px;
  font-weight: 600;
}
.bene-cols {
  margin-bottom: 15px;
}





.mas-service {
  width: 40px;
  padding: 7px 10px 7px 0;
}
.home-subtitle-white {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}
.description-us{
 font-size: 25px;
 color: #70aed7;
 line-height: 25px;
 text-align: left;
 padding-left: 50px!important;
}
.line-us{
  border-left:6px solid  #8dd19e !important;
}
.container-row {
  padding-top: 15px;
}
.containers-photos {
  padding: 15px;
  border-radius: 20px;
}
.containers-photos-left{
  border-top-left-radius: 35px!important;
  border-bottom-left-radius: 35px!important;
}
.imgs-containers {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0px;
}
.containers-photos-right{
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px!important;
  vertical-align: middle!important;
  align-content: center!important;
  padding-left: 20px !important;
  padding-right: 30px !important;
}
.text-title-book{
  font-family: 'MonseratBold',sans-serif !important;
  font-size: 30px;
  color: #fff;
  width: 100%;
  font-weight: 100;
  vertical-align: middle;
  border-bottom: 2px solid #fff !important;
}
.text-desc-book{
  font-size: 15px;
  font-family: 'Roboto',sans-serif !important;
  text-align: left;
  color: #fff;
}
.photos-stants{
  padding: 5px!important;
  float: right;
  margin-left: 0;
}
.back-ground-services{
  background-color: #000;
}
.bg-halo{
  background: #339E9C!important;
}
.bg-cuadra{
  background: #D66921;
}
.bg-teq{
  background: #d66921;
}
.bg-six{
  background: #e50001;
}
.bg-hugo{
  background: #2E2929;
}
.bg-free{
  background: #5f6cca;
}
.bg-xp{
  background: #b03d60;
}
.bg-hp{
  background: #9A212A;
}
.bg-pepsi{
  background: #0c59b1;
}
.bg-img-pepsi{
  background: #e4e4e4;
  border-radius: 25px;
  width: 100%;
}
.img-gallery {
  width: 100%;
}
.img-halo{
  width: 150%!important;
  height: 150%!important;
  padding: 10px;
}
.marquee-img{
  width: 85%;
  padding: 5px 30px;
}
.service-tab{
  font-family: 'Roboto',sans-serif !important;
  font-size: 17px;
  padding-left: 0px;
  margin-left: 0px;
}
.home-services-title{
  position: relative;
  top: -8px;
  color:#fff;
  font-size: 25px;
  line-height: 12px;
  font-weight: 400;
  font-family: 'RobotoBlond',sans-serif !important;
  padding-left: 10px;
  cursor: pointer;
}
.home-services-title:hover {
  color: #8dd19e;
}
.accordion-container {
  padding-left: 10% !important;
}
.home-title-ment{
  color:#8dd19e;
  font-size: 60px;
  text-align: left;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
}
.home-white-proyect{
  color: #fff;
  font-size: 60px;
  text-align: left;
  font-weight: 300;
  font-family: 'RobotoBlond',sans-serif !important;
  background-color: #000;
}
.img-circulo{
  /*width: 8px;*/
}
.margin-service{
  margin-left:0px;
}
.home-desc-section {
  font-family: 'RobotoLight',sans-serif;
  font-size: 20px;
  color: #fff;
}
.home-pos{
  background-color: #000;
  color:#7b9baf;
  font-size: 15px;
  text-align: left;
}
.accordion-container {
  padding-left: 10% !important;
}
.accordion-conten{
  color: #fff!important;
  background-color: #000!important;
}
.accordion-button{
  color: #fff!important;
  background-color: #000!important;
  text-align: left;
  display: contents!important;
}
.accordion{
  border: 1px solid #000000;
}
.accordion-body{
  margin-left: 30px;
}
.accordion-button.collapsed::after {
  background: url('./img/paloma.png');
  background-size: 27px;
  background-repeat: no-repeat;
  height: 27px;
  width: 27px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    display: inline;
}
.accordion-button:not(.collapsed)::after  {
  background: url('./img/paloma.png')!important;
  background-repeat: no-repeat;
  background-size: 27px;
  height: 27px;
  width: 27px;
  float: left;
  text-align: left;
  order: -1; 
  margin-left: 0; 
  margin-right:0.5em; 
  display: inline;
}
.accordion-button:not(.collapsed)::before  {
  background: url('./img/paloma.png');
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    border: none;
}
.accordion-item{
  background-color: #000!important;
  color: #fff!important;
  --bs-accordion-border-color: none;
}
.home-title-black{
  color:#000;
  font-size: 50px;
  text-align: left;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
}
.player-v {
  width: 100% !important;
  /*height: 739px !important;*/
  height: calc( 100vw / 1.778 ) !important;
}
.player-c {
  width: 100% !important;
  height: auto !important;
}
.section {
  padding-top: 80px;
  padding-bottom: 80px;
}
.carousel-card-padding {
  padding: 20px 80px;
}

@media (max-width: 460px) {
  .player-v{
    width: auto !important;
    height: calc( 100vw / 1.778 ) !important;
    padding-right: 1px !important;
  }
  .accordion-button:not(.collapsed)::after{
    background-size: 27px !important;
    --bs-accordion-btn-icon-transform: rotate(-360deg) !important;
    background-size: 27px !important;
    background-repeat: no-repeat !important;
  }
  .footer-text-title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }
  .footer-text-desc{
    padding-bottom: 15px;
  }
  .marquee-img{
    width: 70%;
    padding: 5px 5px;
  }
  .home-title-ment, .home-white-proyect{
    font-size: 50px;
    text-align: center;
  }
  .titles-us {
    font-size: 35px;
    text-align: center;
    padding: 12px !important;
  }
  .carousel-card-padding {
    padding: 20px 20px;
  }
  .last-col {
    padding-top: 24px;
  }
  .line-us {
    border-left: 0px solid #8dd19e !important;
  }
  .description-us {
    text-align: center;
    padding: 0 30px!important;
  }
  .titles-benefits {
    font-size: 40px;
    text-align: center;
  }
  .benefits-card-title {
    text-align: center;
  }
  .benefits-card-desc {
    text-align: center;
  }
  .col-md-6-custom {
    flex: 0 0 auto;
    max-width: 100%;
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  .products-carousel {
    width: 100%;
  }
}